<template>
  <div class="login">
    <img src="../assets/images/logo.png" alt="logo" class="logo" />
    <h1>注册账号</h1>
    <Form :model="formValidate" :rules="ruleValidate" ref="formValidate">
      <FormItem prop="username">
        <Input
          type="text"
          v-model="formValidate.username"
          placeholder="用户"
          autocomplete="off"
        >
          <Icon type="ios-person-outline" slot="prepend"></Icon>
        </Input>
      </FormItem>
      <FormItem prop="email">
        <Input type="text" v-model="formValidate.email" placeholder="邮箱">
          <Icon type="ios-mail" slot="prepend"></Icon>
        </Input>
      </FormItem>
      <FormItem prop="password">
        <Input
          type="password"
          v-model="formValidate.password"
          placeholder="密码"
          autocomplete="off"
        >
          <Icon type="ios-lock-outline" slot="prepend"></Icon>
        </Input>
      </FormItem>
      <FormItem prop="passwordConfirmation">
        <Input
          type="password"
          v-model="formValidate.passwordConfirmation"
          placeholder="重复密码"
          autocomplete="off"
        >
          <Icon type="ios-lock-outline" slot="prepend"></Icon>
        </Input>
      </FormItem>
      <FormItem>
        <div class="button-container">
          <Button
            type="primary"
            size="large"
            @click="register"
            :loading="isLoading"
            >注册
          </Button>
        </div>
      </FormItem>
    </Form>
    <TutorialLink />
    <RouterLink to="/login" class="action-link">登录</RouterLink>
  </div>
</template>

<script>
import { Form, FormItem, Input, Icon, Button, Message } from "view-design";
import { registration } from "../service/auth";
import TutorialLink from "../components/TutorialLink";

export default {
  name: "Registration",
  components: {
    TutorialLink,
    Form,
    FormItem,
    Input,
    Icon,
    Button,
  },
  data() {
    return {
      formValidate: {
        username: "",
        password: "",
        passwordConfirmation: "",
        email: "",
      },
      ruleValidate: {
        username: [
          {
            required: true,
            message: "用户名不能为空",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
        passwordConfirmation: [
          {
            required: true,
            message: "确认密码不能为空",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "邮箱不能为空",
            trigger: "blur",
          },
          { type: "email", message: "邮箱格式错误", trigger: "blur" },
        ],
      },
      isLoading: false,
    };
  },
  methods: {
    async register() {
      this.$refs.formValidate.validate(async (valid) => {
        if (valid) {
          if (
            this.formValidate.password !==
            this.formValidate.passwordConfirmation
          ) {
            return Message.error("确认密码不相同");
          }
          this.isLoading = true;
          try {
            const data = {
              password: this.formValidate.password,
              username: this.formValidate.username,
              email: this.formValidate.email,
            };
            await registration(data);
            Message.success("创建成功");
            this.$router.push("/login");
          } catch (error) {
            Message.error(error.response.data.message);
          }
          this.isLoading = false;
        }
      });
    },
  },
};
</script>

<style scoped>
.login {
  max-width: 600px;
  padding: 0 20px;
  margin: 0 auto;
}

.logo {
  width: 80%;
  display: block;
  margin: 100px auto 50px;
}

.button-container {
  text-align: center;
}

.login h1 {
  text-align: center;
  margin-bottom: 20px;
}

.action-link {
  text-align: center;
  display: block;
}
</style>
