<template>
  <div class="tutorial-link">
    <a
      href="https://swarmso.yuque.com/docs/share/68f677af-4771-4c7a-9fe0-f0b2a88fec70?#%20"
      target="_blank"
    >
      使用教程
    </a>
  </div>
</template>

<script>
export default {
  name: "TutorialLink",
};
</script>

<style scoped>
.tutorial-link {
  text-align: center;
  margin-bottom: 10px;
  margin-top: -5px;
}
</style>
